// extracted by mini-css-extract-plugin
export var bgWrapper = "fullWidth-module--bgWrapper--fd0a5";
export var container = "fullWidth-module--container--b7d55";
export var copyWidth25 = "fullWidth-module--copyWidth25--c7724";
export var copyWidth50 = "fullWidth-module--copyWidth50--f9029";
export var copyWidth75 = "fullWidth-module--copyWidth75--31b96";
export var ctaSection = "fullWidth-module--ctaSection--05a3e";
export var frameColorBlack = "fullWidth-module--frameColorBlack--b3fb2";
export var frameColorLightGray = "fullWidth-module--frameColorLightGray--0966e";
export var frameColorSeashell = "fullWidth-module--frameColorSeashell--e7cca";
export var frameColorSeashellLight = "fullWidth-module--frameColorSeashellLight--0db41";
export var frameColorWhite = "fullWidth-module--frameColorWhite--8282a";
export var frameInner = "fullWidth-module--frameInner--deaba";
export var frameWrapper = "fullWidth-module--frameWrapper--20890";
export var fullWidthModule = "fullWidth-module--fullWidthModule--934ef";
export var gridWrapper = "fullWidth-module--gridWrapper--f2958";
export var isFramed = "fullWidth-module--isFramed--4d949";
export var moduleAlignCenter = "fullWidth-module--moduleAlignCenter--7ce11";
export var moduleAlignRight = "fullWidth-module--moduleAlignRight--f18cf";
export var textAlignLeft = "fullWidth-module--textAlignLeft--02d10";
export var textAlignRight = "fullWidth-module--textAlignRight--ad788";
export var video = "fullWidth-module--video--3813e";
export var videoContainer = "fullWidth-module--videoContainer--b124b";