import { commonTranslations as common } from "@v4/gatsby-theme-talend/src/translations/common";

export const seoPageTranslations = {
    en: {
        related: common.en.relatedArticles,
        moreRelated: common.en.moreRelatedArticles,
        knowledgeCenter: "Knowledge center",
    },
    "en-GB": {
        related: common["en-GB"].relatedArticles,
        moreRelated: common["en-GB"].moreRelatedArticles,
        knowledgeCenter: "Knowledge centre",
    },
    fr: {
        related: common.fr.relatedArticles,
        moreRelated: common.fr.moreRelatedArticles,
        knowledgeCenter: "Centre de connaissances",
    },
    de: {
        related: common.de.relatedArticles,
        moreRelated: common.de.moreRelatedArticles,
        knowledgeCenter: "Knowledge center",
    },
    it: {
        related: common.it.relatedArticles,
        moreRelated: common.it.moreRelatedArticles,
        knowledgeCenter: "Knowledge center",
    },
    ja: {
        related: common.ja.relatedArticles,
        moreRelated: common.ja.moreRelatedArticles,
        knowledgeCenter: "ナレッジセンター",
    },
};
