import React from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Video from "@v4/gatsby-theme-talend/src/components/Video/Video";
import stylingOptions from "@v4/utils/stylingOptions";
import formatClassName from "@v4/utils/formatClassName";

export const fragment = graphql`
    fragment FullWidth on ContentfulFullWidth {
        id
        name
        stylingOptions {
            ...stylingOptions
        }
        headingTextShade
        headingAlignment
        heading {
            raw
            references {
                ...RTEReferences
            }
        }
        bodyTextShade
        bodyAlignment
        body {
            raw
            references {
                ...RTEReferences
            }
        }
        copyWidth
        video
        cta {
            ...Cta
        }
        footNote
        variation
        frameBackground
    }
`;

const FullWidth = ({ CTA, formatContentfulRichText, cx, ...props }) => {
    const styleOpts = stylingOptions(props.stylingOptions, cx);

    // Set up rich-text content
    const heading = props.heading && props.heading.raw ? renderRichText(props.heading) : null;
    const body = props.body && props.body.raw ? renderRichText(props.body, formatContentfulRichText()) : null;

    // Handle Video
    const video = props.video ? props.video : null;

    // Alignment options for cx classes:
    const hAlign = props.headingAlignment ? "textAlign" + props.headingAlignment : null;
    const bAlign = props.bodyAlignment ? "textAlign" + props.bodyAlignment : null;

    // Text shade options for cx classes:
    const hShade = props.headingTextShade ? "textShade" + props.headingTextShade : null;
    const bShade = props.bodyTextShade ? "textShade" + props.bodyTextShade : null;

    // Copy width option for cx classes
    const copyWidth = props.copyWidth ? `copyWidth${String(props.copyWidth).replace("%", "")}` : null;

    // "Framed" variation with background and inherited styles
    const variation = props.variation ? "isFramed" : null;
    const frameBg = variation && props.frameBackground ? formatClassName("frameColor", props.frameBackground) : "";

    // Varible to hold styles pertaining to a variation of the FullWidth module
    let variationStyles = "";

    // Shared styles from the module's styling options when the variation is set to "framed"
    if (variation === "isFramed") {
        variationStyles = [
            variation,
            "frameWrapper",
            styleOpts.options?.bgColor,
            styleOpts.options?.bgGradient,
            styleOpts.options?.spacingTop,
            styleOpts.options?.spacingBottom,
        ];
    }

    const classString = styleOpts.classString ?? "";

    return (
        <section
            id={props.moduleId}
            className={`${cx("fullWidthModule", variation, frameBg)} ${classString} ${frameBg}`}
            style={variation !== "isFramed" ? styleOpts?.bgHex : null}
        >
            {styleOpts && styleOpts.borderTopEl}
            <div
                className={cx("gridWrapper", variationStyles)}
                style={variation === "isFramed" ? styleOpts?.bgHex : null}
            >
                {(heading || body || video) && (
                    <div className={`${cx("container", copyWidth)} copyContainer`}>
                        {heading && <div className={cx(`heading`, hAlign, hShade)}>{heading}</div>}
                        {body && <div className={cx(`body`, bAlign, bShade)}>{body}</div>}
                        {video && (
                            <div className={cx(`videoContainer`, bAlign, bShade)}>
                                <Video videoSrcURL={video} videoTitle={heading} customClass={cx("video")} />
                            </div>
                        )}
                    </div>
                )}
                {props.cta && (
                    <div className={cx("container")}>
                        <div className={cx(`ctaSection`)}>
                            {props.cta.map((cta, index) => (
                                <CTA key={`cta-${index}`} {...cta} />
                            ))}
                        </div>
                        {props.footNote && <div className={"font-light"}>{props.footNote}</div>}
                    </div>
                )}
                {styleOpts && styleOpts.bgImageEl}
            </div>
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    );
};

export default FullWidth;
