import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout/Layout";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import FullWidth from "../../components/modules/fullWidth/FullWidth";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import { getLangPrefixedPath, getPrefixedPathByPageType } from "@v4/utils/pathPrefix";
import { seoPageTranslations } from "../../translations/seoPage";
import { useMediaQuery } from "react-responsive";
import { isTabletOrLargerQuery } from "../../utils/mediaQueries";

export const query = graphql`
    query($id: String!, $locale: String!, $language: String!, $categories: [String]!) {
        contentfulSeoPage(id: { eq: $id }) {
            id
            slug
            node_locale
            languages
            title
            metaFields {
                ...meta
            }
            subheading {
                raw
                references {
                    ...RTEReferences
                }
            }
            content {
                raw
                references {
                    ...RTEReferences
                }
            }
            modules {
                __typename
                ... on Node {
                    ...FullWidth
                }
            }
            # Sub-category that links to this SEO page.
            categories {
                id
                title
                # L2 pages are linked to from sub-categories.
                links {
                    title
                    slug
                }
                # Parent category that links to the sub-category that links to this page.
                seo_category {
                    title
                    slug
                }
            }
        }
        relatedContent: allContentfulSeoPage(
            sort: { fields: createdAt, order: DESC }
            filter: {
                categories: { elemMatch: { title: { in: $categories } } }
                id: { ne: $id }
                node_locale: { eq: $locale }
                languages: { in: [$language, "All"] }
            }
        ) {
            nodes {
                id
                node_locale
                slug
                name
                title
            }
        }
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

export default function SeoPage({ data, pageContext }) {
    // Shortcut
    const seoPage = data.contentfulSeoPage;
    const { locale } = pageContext;

    // Data for use with footer components
    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    // Translations.
    const translations = seoPageTranslations[locale];

    // Track window size -- show all articles at the bottom for mobile, pull
    // some out into a sidebar callout for tablet and up.
    const isTablet = useMediaQuery(isTabletOrLargerQuery);

    // Meta values for the SEO component
    const metaValues = seoPage.metaFields ?? {};

    const parentCategory = seoPage?.categories?.[0]?.seo_category?.[0];

    // Set up breadcrumbs object
    const crumbList = {};

    // L0
    crumbList[translations.knowledgeCenter] = "/knowledge-center/";

    // L1
    if (parentCategory?.title && parentCategory?.slug) {
        crumbList[parentCategory?.title] = getPrefixedPathByPageType(parentCategory?.slug, "ContentfulSeoCategory");
    }

    // L2 if on L3 page
    const levelTwoPage = seoPage?.categories?.[0]?.links?.[0];
    if (levelTwoPage?.slug && levelTwoPage.slug !== seoPage.slug) {
        crumbList[levelTwoPage?.title] = levelTwoPage?.slug;
    }

    // L2 if on L2 page, L3 if on L3 page.
    crumbList[seoPage.title] = null;

    // Modules field currently only supports full-width.
    const Modules = ({ modules }) =>
        modules.map((el, i) => (el.__typename === "ContentfulFullWidth" ? <FullWidth key={i} {...el} /> : null));

    // Tablet and up display 5 articles in a sidebar, on mobile display everything at the bottom.
    let topRelatedContent = data.relatedContent.nodes?.slice(0, 5);
    let [allOtherRelatedContent, setAllOtherRelatedContent] = useState(data.relatedContent?.nodes);

    useEffect(() => {
        setAllOtherRelatedContent(isTablet ? data.relatedContent.nodes?.slice(5) : data.relatedContent?.nodes);
    }, [isTablet, data.relatedContent.nodes]);

    // Related articles sidebar callout (only for tablet and up).
    const TopRelatedContent = () => (
        <div className="mb-2 p-3 shadow-lg border border-c_light_grey relative z-10 hidden md:block md:w-52 md:float-right md:ml-2">
            <h5>{translations.related}</h5>
            <ul>
                {topRelatedContent.map(({ id, slug, title, node_locale }) => (
                    <li key={id} className="mb-2">
                        <Link
                            to={getLangPrefixedPath(slug, node_locale)}
                            className="hover:text-c_primary hover:underline text-base"
                            language={node_locale}
                        >
                            {title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );

    // All remaining related articles (all articles if <tablet).
    const AllOtherRelatedContent = () => (
        <div className="container px-2 md:pb-5 lg:pb-10">
            <div className="p-3 mb-2 py-5  relative z-10 md:border md:border-c_light_grey md:px-8 md:shadow-lg desktop:px-16">
                <h4>{translations.moreRelated}</h4>
                <ul className="md:grid md:grid-cols-2 md:gap-3">
                    {allOtherRelatedContent.map(({ id, slug, title, node_locale }) => (
                        <li key={id} className="ml-0 list-none mb-2 md:mb-0">
                            <Link
                                to={getLangPrefixedPath(slug, node_locale)}
                                className="hover:text-c_primary hover:underline text-base"
                                language={node_locale}
                            >
                                {title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );

    const showTopSideBar = data.relatedContent.nodes?.length > 0;

    return (
        <Layout headerMenu={data.contentfulMenu} footerMenu={footerData} pageStyleProps={{}}>
            <SEO
                title={metaValues.metaTitle || seoPage.title}
                pathname={seoPage.slug}
                description={metaValues.metaDescription}
                canonicalUrl={metaValues.canonicalUrl}
                canonicalUrlOverride={metaValues.canonicalUrlOverride}
                robots={metaValues.metaRobot}
                bingBot={metaValues.bingBot}
                googleBot={metaValues.googleBot}
                metaImage={metaValues?.metaImage ?? null}
                modifiedTime={seoPage.updatedAt}
                createdTime={seoPage.createdAt}
                languages={seoPage.languages}
                type={`Article`}
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            <article>
                <section className="pt-8 bg-gradient-to-t from-c_grad_seo_resources-end to-c_grad_seo_resources-start">
                    <div className="container px-2 md:px-4">
                        <h1 className="mb-2 text-4xl md:text-h1">{seoPage.title}</h1>
                        <div className="mb-5">
                            {seoPage.subheading?.raw && renderRichText(seoPage.subheading, formatContentfulRichText())}
                        </div>
                        <Breadcrumbs crumbList={crumbList} />
                    </div>
                </section>

                <section className={`container px-2 md:px-4 text-c_slate ${showTopSideBar && "pageWithSideBar"}`}>
                    {showTopSideBar ? <TopRelatedContent /> : null}
                    {seoPage.content?.raw && renderRichText(seoPage.content, formatContentfulRichText())}
                </section>
                {seoPage.modules && <Modules modules={seoPage.modules} />}
                {allOtherRelatedContent?.length > 0 ? <AllOtherRelatedContent /> : null}
            </article>
        </Layout>
    );
}
